import { useCategoriesStore } from "./categoriesStore";
import { useAPI } from "~/uses/useMyFetch";
import { useMemoize } from "@vueuse/core";
import { Product } from "~/models/product.model";

export const useComparisonStore = defineStore("comparisonStore", {
  state: () => {
    return {
      products: [],
      currentCategoryProducts: [],
      currentCategory: null,
      isShownSpecificationsDifference: false,
      isFetching: false,
      memoizedFetch: null,
    };
  },
  actions: {
    async fetch() {
      if (null === this.memoizedFetch) {
        this.memoizedFetch = useMemoize(this.forceFetch);
      }

      return this.memoizedFetch();
    },
    async forceFetch() {
      const categoriesStore = useCategoriesStore();
      const { getTopParentCategory: topParent, getCategoryById } =
        storeToRefs(categoriesStore);
      this.isFetching = true;

      const getTopParentCategory = (id) =>
        topParent.value(getCategoryById.value(id));

      await useAPI("/catalog/product/list/comparison", {
        params: {
          "fields[]": "specifications",
        },
      })
        .then((data) => {
          if (data.length > 0) {
            this.products = data.map(({ product }) => {
              return {
                product: new Product(product),
                parentCategory: getTopParentCategory(product.categories[0].id),
              };
            });

            this.currentCategory = this.products[0].parentCategory;
            this.currentCategoryProducts = this.products.filter(
              ({ parentCategory }) =>
                parentCategory.id === this.currentCategory.id,
            );
          }
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    async addItem(product) {
      await useAPI("/catalog/product/comparison/add", {
        method: "POST",
        body: {
          productId: product.id,
        },
      });

      await this.forceFetch();
    },
    async removeItem(product) {
      await useAPI("/catalog/product/comparison/remove", {
        method: "POST",
        body: {
          productId: product.id,
        },
      });

      this.products = this.products.filter(
        (comparisonProduct) => comparisonProduct.product.id !== product.id,
      );

      this.currentCategoryProducts = this.products.filter(
        ({ parentCategory }) => parentCategory.id === this.currentCategory.id,
      );

      if (
        0 === this.currentCategoryProducts.length &&
        0 !== this.products.length
      ) {
        this.setCurrentCategory(this.getCategories[0]);
      }

      await useAPI("/catalog/product/list/comparison", {
        params: { "fields[]": "specifications" },
      });
    },

    setCurrentCategory(category) {
      this.currentCategory = category;
      this.currentCategoryProducts = this.products.filter(
        ({ parentCategory }) => parentCategory.id === this.currentCategory.id,
      );
    },

    toggleShowDiff() {
      this.isShownSpecificationsDifference =
        !this.isShownSpecificationsDifference;
    },
  },
  getters: {
    getProducts: (state) => state.products,
    getIsFetching: (state) => state.isFetching,
    getIsProductInList() {
      return (id) =>
        this.products.some(
          (comparisonProduct) => comparisonProduct.product.id === id,
        );
    },
    // getCategories() {
    //   const map = []
    //
    //   this.products.forEach((product) => {
    //     map[product.parentCategory.id] = product.parentCategory
    //   })
    //
    //   return Object.values(map)
    // },
    getCategories() {
      const result = [];

      this.products.forEach((comparisonProduct) => {
        const isAdded = result.find(
          (el) => el.id === comparisonProduct.parentCategory.id,
        );

        if (!isAdded) {
          result.push({ ...comparisonProduct.parentCategory, qty: 1 });
        } else {
          result.find(
            (el) => el.id === comparisonProduct.parentCategory.id,
          ).qty += 1;
        }
      });

      return result;
    },
    getSpecList: (state) => {
      const specifications = {};

      state.currentCategoryProducts.forEach(({ product }) => {
        product.specifications.forEach(({ items }) => {
          items.forEach(({ slug, name, option }) => {
            //Создание уникальных спецификаций
            if (!Object.keys(specifications).includes(slug)) {
              specifications[slug] = {
                name,
                options: [],
              };
            }

            //Наполнение спецификаций опциями
            specifications[slug].options.push(option.value);

            //Проверка опций на уникальность
            specifications[slug].hasDifferentOptions = specifications[
              slug
            ].options.some((optionName) => {
              return (
                getLocalizedName(optionName) !== getLocalizedName(option.value)
              );
            });
          });
        });
      });

      return specifications;
    },
  },
});
